/* You can add global styles to this file, and also import other style files */

// Bootstrap override
$primary: #4E3BB8;
$success: #1BCF83;
$info: #E5E1FF;

@import "~bootstrap/scss/bootstrap";

@import "./assets/scss/styles.scss";

@import '~video.js/dist/video-js.css';

@import "~@ng-select/ng-select/themes/default.theme.css";

.font-emoji {
  font-family: 'Noto Color Emoji', sans-serif;
}

.ng-select.custom {
  border:0px;
  min-height: 0px;
  border-radius: 0;
  width: 100px;
  font-size: 14px;
}
.ng-select.custom .ng-select-container  {            
  min-height: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 38px;
}
