@import "utils/variables";
@import "utils/fonts";

:root {
    --theme-deafult: #4E3AB8;
    --theme-secondary: #f73164;
    --primary-title: #5B9FD6;
    --primary-dark: #4E3BB8; //#203E73;
    --primary-gray: #F8F8F8;
    --dark-gray: #CFCECD;
    --dark: #030303;
    --gray-sub: #757575;
    --bs-gutter-x: 0.5rem;
}
@import "components/buttons.scss";
@import "components/card.scss";
@import "components/cover-indicator.scss";

@import "pages/login.scss";
@import "@angular/material/prebuilt-themes/indigo-pink.css";

body {
    font-family: 'Lato', sans-serif;
    font-size: 12px;
}

.page-title {
    color: var(--primary-dark);
    border-bottom: 2px solid var(--primary-dark);;
    h1 {
        margin-bottom: 0.7rem;
    }
    @media screen and (max-width: 450px) {
        h1 {
            font-size: calc(1.2rem + 1.5vw);
        }
    }
}

.font-14 {
    font-size: 14px;
}

.fw-900 {
    font-weight: 900;
}

.bg-dark-gray {
    background-color: var(--dark-gray);
}
.text-dark-gray {
    color: var(--dark-gray);
}
.text-gray-sub {
    color: var(--gray-sub);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab{
    color: var(--primary-dark)
}

.text-primary-dark {
    color: var(--primary-dark);
}
.text-primary-title {
    color: #303030;
}
.bg-primary-gray{
    background-color: var(--primary-gray);
}

.text-gray-sub {
    color: --var(--gray-sub)
}

.text-theme {
    color: var(--theme-deafult);
}
.card {
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 15px 12px;
}
.card-column {
    h6{
        font-size: 14px;
    }
}

/** Colors */
.bg-whatsapp {
    background: #63D75F;
    color: white;
}
.bg-facebook {
    background: #3978EB;
    color: white;
}
.bg-twitter {
    background: #4DA0EB;
    color: white;
}
.bg-purple {
    background: #AF4DEB;
    color: white;
}
.bg-pdf {
    background: #f40f02;
    color: white;
}

.avatar-person{
    width: 80px;
    align-items: center;
    text-align: center;
    .avatar-person-img{
        width: 45px;
        height: 45px;
    }
}

.section-title{
    border-bottom: 1px solid var(--primary-dark);
    color: var(--primary-dark);
}


.headline-title{
    .title {
        font-size: 14px;
        font-weight: 400;
        padding-left: 10px;
    }
    margin-bottom: 3px;
}
.headline-content {
    border-top: 1px solid var(--primary-dark);
    background-color: #FDFDFD;
    & p {
      color: #757575;
      font-size: Lato;
      margin-bottom: 0 !important;
    }
}


.cover-picture{
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 50vh;
    img{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: -1;
    }
    .overlay{
        background: linear-gradient(184deg, rgb(255 255 255 / 0%) -37.35%, #ffffff 82.14%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 102%;
        z-index: 0;
    }
}
.buttons{
    z-index: 4;
    position: relative;
}

.cover-indicators{
    margin-top: -60px;
    z-index: 1;
    position: relative;
}
.cover-name{
    svg{
        height: 14px;
    }
}
.tag-picture{
    height: 310px;
    overflow: hidden;
}

.topic-buttons{
    color: var(--dark-gray);
    .active {
        border-radius: 3em;
        background-color: var(--primary-dark);
        font-weight: 500;
        color: white;
    }
}

.text-14 {
    font-size: 14px;
}

.avatar{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid var(--dark-gray);
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    img {
        width: 100%;
    }
}
.view-more {
    display: none;
}

.view-less {
    display: block;
    background-color: #fafafa;
    width: 100%;
    text-align: center;
    padding: 10px;
    color: var(--primary-dark);
}

.tag-content {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    color: var(--dark);
    padding-right: 10px;
    padding-left: 10px;
    &.content-hidden {
        &>div{
            max-height: 80px;
            overflow: hidden;
        }
        .view-more {
            display: block;
            background-color: #fafafa00;
            width: 100%;
            text-align: center;
            padding: 10px;
            color: var(--primary-dark);
        }
        .view-less {
            display: none;
        }
    }
}

.tag-avatar-detail {
    width: 65px;
    height: 65px;
    border: 2px solid #E5E5E5;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    img {
        width: 100%;
        height: 100%;
    }
}
.cover-name {
    background-color: var(--primary-dark);
    color: white;
    height: 245px;
}
.overlay-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.1;
    z-index: 0;
}
.tag-name {
    z-index: 1;
}

.tag-preview {
    border-radius: 35px;
    display: flex;
    border: 1px solid #F8F8F8;
    background-color: white;
    font-family: "Overpass", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: var(--primary-dark);
    padding-right: 5px;
    margin-right: 5px;
    word-break: keep-all;
    height: 24px;
    align-items: center;
    white-space: nowrap;
    img {
        border-radius: 18px;
        width: 24px;
        height: 24px;
        vertical-align: middle;
    }
}

.non-exclusive {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Overpass;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 20px;
    color: var(--primary-dark);
}

mat-sidenav {
    width: 230px;
    border-right: none;
    background-color: var(--primary-dark);
    color: white;
    border-top-right-radius: 70px;
}

.mat-drawer{
    background-color: var(--primary-dark);
    color: white;
}

.mat-drawer-container {
  background-color: white;
}

mat-sidenav-container {
    height: 100vh;
}

.mat-mini-fab .mat-button-wrapper {
    padding: 0px 0 8px!important;
}

.svg-sm svg{
    width: 18px;
    height: 18px;
}

.circle-btn svg {
    width: 16px;
    height: 16px;
    margin-top: -4px;
}

.suscribe-block {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    height: 222px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: linear-gradient(180deg, #f8f8f8cf 0%, #f8f8f8 28.06%);
}

.hidden-no-sesion {
    max-height: 40vh;
    overflow: hidden;
}

.comment-share-bar {
    text-align: center;
    color: white;
    font-weight: bold;
    padding: 3px;
    margin: 1rem -1rem -0.5rem;
}

.ql-align-justify br {
    display: none;
}

.column-content img, .headline-content img {
    max-width: 100%;
}

input[type="text"], input[type="password"] {
    font-size: inherit;
}

.content-card img {
    display: none;
    max-width: 100%;
}

.hint-group{
    margin: 10px 0;
    .type {
      color: #CFCECD;
    }
    .tagname {
      font-size: 20px;
    }
}
