@font-face {
    font-family: 'PortadaEb';
    src: url('../../fonts/PortadaEb.ttf') format('truetype'),
}

@font-face {
    font-family: 'PortadaEb-Italic';
    src: url('../../fonts/PortadaEb-Italic.ttf') format('truetype'),
}

@font-face {
  font-family: "Roboto";
  src: url('../../fonts/Roboto-Regular.ttf') format('truetype'),
  }


.title-font {
    font-family: 'PortadaEb', 'sans-serif';
}

.title-font-italic {
    font-family: 'PortadaEb-Italic', 'sans-serif';
}
