.cover-indicator-value {
    h4 {
        font-weight: 900;
        font-size: 24px;
    }
    font-size: 14px;
    font-weight: 600;
}

.cover-indicator {
    font-size: 14px;
}

.cover-indicator-img {
    width: 50px;
    height: 50px;
}